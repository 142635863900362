import React from 'react'
import { Link } from 'react-scroll'
import './Header.css'
import logo from '../../assets/logo.png'
import bars from '../../assets/bars.png'
import { useState } from 'react'
const Header = () => {
  const [mobile , setMobile]=useState(window.innerWidth<=768?true:false) 
  const [mobileMenu , setmobileMenu]=useState(window.innerWidth<=768?true:false) 
  return (
    <div className='header'>
      <img className='logo' src={logo} alt="" />
      {mobile && mobileMenu?<img onClick={()=> setMobile(false)} className='bars' src={bars} alt="" />:<ul className='header-m'>
        <Link span={true} smooth={true} onClick={()=> setMobile(true)} to='home'><li>Home</li></Link>
        <Link span={true} smooth={true} onClick={()=> setMobile(true)} to='programs'><li>Programs</li></Link>
        <Link span={true} smooth={true} onClick={()=> setMobile(true)} to='reasons'><li>Why Us</li></Link>
        <Link span={true} smooth={true} onClick={()=> setMobile(true)} to='plans'> <li>Plans</li></Link>
        <Link span={true} smooth={true} onClick={()=> setMobile(true)} to='testimonials'><li>Testimonials</li></Link>
      </ul>}

      
      
    </div>
  )
}

export default Header
