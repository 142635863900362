import React from "react";
import "./Programs.css";
import rightArrow from '../../assets/rightArrow.png'
import programsData from '../../data/programsData'
const Programs = () => {
  return (
    <div className="programs" id="programs">
      {/* HEADER OF PROGRAMS  */}
      <div className="programs-header">
        <span className="stroke-text">Explore Our</span>
        <span>Program</span>
        <span className="stroke-text">To Shape You</span>
      </div>
      {/* PROGRAMS CATEGORIES  */}
      <div className="programs-categories">
        {programsData.map((program,i)=>{
            return <div key={i} className="category">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="join-now">Join Now: <span><img src={rightArrow} alt="" /></span></div>
            </div>
        })}
      </div>
    </div>
  );
};

export default Programs;
