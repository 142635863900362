import React from "react";
import Header from "../header/Header";
import "./Hero.css";
import heart from "../../assets/heart.png";
import hero_image_back from "../../assets/hero_image_back.png";
import hero_image from "../../assets/hero_image.png";
import calories from "../../assets/calories.png";
import { motion } from "framer-motion"; 
import NumberCounter from 'number-counter'
const Hero = () => {
  const mobile= window.innerWidth<=768?true:false;
  return (
    <div className="hero" id="home">
      {/* LEFT OF HERO  */}
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        {/* BEST ADD  */}
        <div className="the-best-ad">
          <motion.div
            transition={{
              duration: 2.5,
              delay: 0.3,
              type: "tween",
            }}
            initial={{ x: mobile?"48vw": "18vw" }}
            whileInView={{ x: "0vw" }}
          ></motion.div>
          <span>the best fitness clube in the town</span>
        </div>

        {/* HEARO HEADING  */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>your</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div>
            <span>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Autem.
              Lorem ipsum dolor sit. Lorem ipsum dolor sit.
            </span>
          </div>
        </div>
        {/* FIGURES  */}
        <div className="figures">
          <div>
            <span><NumberCounter
            start={100}
            end={140}
            preFix='+'
            delay={5}
            /></span>
            <span>expert coaches</span>
          </div>
          <div>
            <span><NumberCounter
            start={900}
            end={987}
            preFix='+'
            delay={5}
            /></span>
            <span>member joined</span>
          </div>
          <div>
            <span><NumberCounter
            start={0}
            end={50}
            preFix='+'
            delay={3}
            /></span>
            <span>Programs</span>
          </div>
        </div>
        {/* HERO BUTTONS  */}
        <div className="hero-buttons">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>

      <div className="right-h">
        <button className="btn">Join Now</button>
        <motion.div
          transition={{
            duration: 2.5,
            delay: 0.3,
            type: "spring",
          }}
          initial={{ x: "120px" }}
          whileInView={{ x: "0" }}
          className="heart-rate"
        >
          <img src={heart} alt="" />
          <span>Heart Rate</span>
          <span>120 bpm</span>
        </motion.div>

        {/* HERO AND HERO IMAGE BACK  */}

        <img className="hero_image" src={hero_image} alt="" />
        <motion.img
          transition={{
            duration: 2.5,
            delay: 0.3,
            type: "spring",
          }}
          initial={{ x: "20vw" }}
          whileInView={{ x: "0" }}
          className="hero_image_back"
          src={hero_image_back}
          alt=""
        />

        {/* CALORIES IMAGE  */}
        <motion.div
          transition={{
            duration: 2.5,
            delay: 0.3,
            type: "spring",
          }}
          initial={{ x: "-180px" }}
          whileInView={{ x: "0" }}
          className="calories"
        >
          <img src={calories} alt="" />
          <div>
            <span>Calories Burned </span>
            <span> 220 Kcal </span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
