import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./Joins.css";
const Joins = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_rpugrgr', 'template_56c8nke', form.current, 'ffEA4ETQ_rX82YGVI')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="join" id="join-us">
      {/* LEFT OF JOIN  */}
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">READY TO </span>
          <span>LEVEL UP </span>
        </div>
        <div>
          <span>YOUR BODY </span>
          <span className="stroke-text">WITH US?</span>
        </div>
      </div>

      {/* RIGHT OF JOIN  */}
      <div className="right-j">
        <form ref={form} className="email-container" onClick={sendEmail}>
            <input type="email" name="user_email" placeholder="Enter your email" />
            <button className="btn btn-j">Join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Joins;
