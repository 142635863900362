import React from "react";
import "./Testimonials.css";
import testimonialsData from "../../data/testimonialsData";
import { useState } from "react";
import leftArrow from "../../assets/leftArrow.png"
import rightArrow from "../../assets/rightArrow.png"
import { motion } from "framer-motion";
 
const Testimonials = () => {
  const [selected, setSelected] = useState(0);
  const len= testimonialsData.length;
  return (
    <div className="testimonials" id="testimonials">
      <div className="left-t">
        <span>TESTIMONIALS</span>
        <span className="stroke-text">WHAT THEY</span>
        <span>SAY ABOUT US</span>
        <motion.span
        key={selected}
        transition={{
          duration: 2.5, 
          type:"spring"
      }}
      initial={{opacity:0, x:'-15vw'}}
      animate={{opacity:1, x:'0' }} 
        >{testimonialsData[selected].review}</motion.span>
        <span>
          <span style={{ color: "var(--orange)" }}>
            {testimonialsData[selected].name}
          </span>
          <span> - {testimonialsData[selected].status}</span>
        </span>
      </div>
      <div className="right-t">
        <motion.div
        transition={{
          duration: 2.5,
          delay: 0.3, 
          type:"spring"
      }}
      initial={{opacity:0, x:'15vw'}}
      whileInView={{opacity:1, x:'0' }}
        ></motion.div>
        <motion.div
        transition={{
          duration: 2.5, 
          type:"spring"
      }}
      initial={{opacity:0, x:'-15vw'}}
      whileInView={{opacity:1, x:'0' }}
        
        ></motion.div>
        <motion.img 
        key={selected}
        transition={{
          duration: 2.5, 
          type:"spring"
      }}
      initial={{opacity:0, x:'15vw'}}
      animate={{opacity:1, x:'0' }} 

        src={testimonialsData[selected].image} alt="" />
        <div className="arrows">
          <img
          onClick={()=>{selected===0?setSelected(len-1):setSelected((prev)=> prev-1)}}
          
          src={leftArrow} alt="" />
          <img
          onClick={()=>{selected===len-1?setSelected(0):setSelected((prev)=> prev+1)}} src={rightArrow} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
