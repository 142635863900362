import "./App.css";  
import Footer from "./components/footer/Footer";
import Hero from "./components/hero/Hero";
import Joins from "./components/joins/Joins";
import Plans from "./components/plans/Plans";
import Programs from "./components/programs/Programs";
import Reasons from "./components/reasons/Reasons"; 
import Testimonials from "./components/testimonials/Testimonials";
function App() {
  return <div className="App"> 
          <Hero/>
          <Programs/>
          <Reasons/>
          <Plans/>
          <Testimonials/>
          <Joins/>
          <Footer/>
    </div>;
}

export default App;
