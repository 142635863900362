import React from "react";
import "./Plans.css";
import plansData from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
const Plans = () => {
  return (
    <div className="plans-container" id="plans">
      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>
      <div className="programs-header">
        <span className="stroke-text">READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className="stroke-text">NOW WITHUS</span>
      </div>
      <div className="plans">
        {plansData.map((plan,i)=>{
            return <div key={i} className="plan">
                {plan.icon}
                <span>{plan.name}</span>
               <span> ${plan.price}</span>
                <div className="features">
                    {plan.features.map((feature,i)=>{
                        return<div key={i} className="feature">
                            <img src={whiteTick} alt="" />
                            <span>{feature}</span>
                        </div>
                    })}
                </div>
                <div><span>see more benifits</span></div>
                <button className="btn join-now">
                    Join Now
                </button>
            </div>
        })}
      </div>
    </div>
  );
};

export default Plans;
